<script>
import dayjs from 'dayjs'
import FlipCountdown from './clocks/Clock.vue'

export default {
  components: { FlipCountdown },
  props: {
    devise: { type: Object },
    clock: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      theClock: null,
    }
  },
  computed: {
    deadline() {
      const date = dayjs(this.clock.end_date)
      return date.format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<template>
  <div v-if="clock && clock.end_date && deadline" class="flipcontainer">
    <FlipCountdown :deadline="deadline" style="display: block !important" />
  </div>
</template>

<style>
  .flip-clock
  .flip-clock-group
  .flip-clock-list
  .flip-clock-list-item-inner
  > .top,
.flip-clock
  .flip-clock-group
  .flip-clock-list
  .flip-clock-list-item-inner
  > .bottom {
  background-color: blue;
}
</style>
